<template>
  <el-row :gutter="20" class="page-box task-list-page">
    <el-col>
      <Breadcrumb
        :home="false"
        :breadcrumbs="[
          {
            label: 'MAC资源管理',
            to: '/macRequisition',
          },
        ]"
      />
    </el-col>
    <el-col>
      <el-card class="grid-card" shadow="never">
        <SchemaTable
          ref="schematableref"
          :schema="schema"
          :model="dataModel"
          :search="search"
          :filters="filters"
          :queryConf="queryConf"
          :hasReset="hasReset"
          :hasAction="hasAction"
          :canDelete="false"
          :canCreate="true"
        >
        <template #actionBtns="{ row }">
            
            </template>
        </SchemaTable>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./macRequisition.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import axios from "axios";

export default {
  components: {
    SchemaTable,
    Breadcrumb,
  },
  data() {
    return {
      hasAction: false,
      search: "",
      filters: ['proposer'],
      schema,
      hasReset: true,
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.macRequisition}`,
        getListMap(item) {
          item.createdTime = +new Date(item.created_at);
          return item;
        },
        getApi: `${uris.macRequisition}${attachId}`,
        getMap(res) {
          // res.isEiot = true;
          res.type = res.type +"";
          return res;
        },
        deleteApi: `${uris.macRequisition}${attachId}`,
        createApi: uris.macRequisition,
        updateApi: `${uris.macRequisition}${attachId}`,
        query: { pageNumber: 1, pageSize: 10 },
        axiosConfig: {
          timeout: 10000,
        },
      }),
    };
  },
  methods: {
    
  },
};
</script>

<style scoped>
</style>